<template>
  <div class="app_con">
    <div class="p-20">
      <div class="search">
        <el-input
          clearable
          placeholder="输入设备名称"
          v-model="filtterData.deviceName"
          class="searchInput"
        >
          <template slot="prepend">设备名称</template>
        </el-input>
        <el-input
          clearable
          placeholder="输入设备ip"
          v-model="filtterData.deviceIp"
          class="searchInput"
        >
          <template slot="prepend">设备ip</template>
        </el-input>

        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">设备状态</div>
          <el-select
            v-model="filtterData.deviceStatus"
            clearable
            placeholder="选择状态"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        :data="deviceList"
        border
        @row-dblclick="rowClick"
        style="width: 100%"
      >
        <template v-for="v in props">
          <el-table-column
            :key="v.id"
            :prop="v.prop"
            :label="v.label"
            :width="v.width ? v.width : ''"
          >
            <template slot-scope="scope">
              <span
                class="status-radius"
                :class="setColorClass(scope.row.deviceStatus)"
                v-if="v.type && v.type == 'status'"
              >
                {{
                  statusManage.deviceStatus(scope.row.deviceStatus).name
                }}</span
              >
              <span
                :class="setColorClass(scope.row.validity)"
                v-else-if="v.type == 'validity'"
              >
                {{ scope.row.validity }}</span
              >
              <span v-else>{{ scope.row[v.prop] }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <el-dialog
      width="80%"
      :close-on-click-modal="false"
      title="查看设备信息"
      :visible.sync="dialogTableVisible"
    >
      <el-table
        border
        :data="accessControlChannelList"
        :header-cell-style="{ background: '#e8e8e8' }"
      >
        <el-table-column width="80" property="channelSeq" label="通道号">
          <template slot-scope="scope">
            <span>{{ scope.row.channelSeq + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="channelName"
          label="通道名称"
        ></el-table-column>
        <el-table-column
          property="channelCode"
          label="通道编码"
        ></el-table-column>
        <el-table-column width="100" property="status" label="门状态">
          <template slot-scope="scope">
            <el-tag :type="statusManage.doorStatus(scope.row.status).type">{{
              statusManage.doorStatus(scope.row.status).name
            }}</el-tag>
          </template>
          <!-- statusManage.doorStatus() -->
        </el-table-column>
        <el-table-column
          property="delayTime"
          label="延时时间(秒)"
        ></el-table-column>
        <el-table-column property="orgName" label="所属组织"></el-table-column>
        <el-table-column property="address" label="读卡器名称">
          <template slot-scope="scope">
            <div
              class="m-b-10"
              v-for="item in scope.row.cardReaderList"
              :key="item.id"
            >
              <el-tag>{{ item.name }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="address" label="读卡器类型">
          <template slot-scope="scope">
            <div
              class="m-b-10"
              v-for="item in scope.row.cardReaderList"
              :key="item.id"
            >
              <el-tag>{{
                statusManage.businessType(item.businessType).name
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column property="address" label="识别方式">
          <template slot-scope="scope">
            <div
              class="m-b-10"
              v-for="item in scope.row.cardReaderList"
              :key="item.id"
            >
              <el-tag>{{ statusManage.openType(item.openType).name }}</el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import statusManage from "./utils/status";
export default {
  data() {
    return {
      input: "",
      dialogTableVisible: false,
      accessControlChannelList: [],
      apiName: "/tagInfo",
      deviceList: [],
      statusManage,
      statusOptions: [
        { value: "ON", label: "在线" },
        { value: "OFF", label: "离线" },
      ],
      searchQuery: {
        pageNum: 1,
        pageSize: 1000,
      },
      filtterData: {
        deviceName: "",
        deviceIp: "",
        deviceStatus: "",
      },
      props: [
        {
          label: "ID",
          prop: "id",
          align: "center",
        },
        {
          label: "设备名称",
          prop: "deviceName",
          align: "center",
        },
        {
          label: "设备IP",
          prop: "deviceIp",
          align: "center",
        },
        {
          label: "设备编号",
          prop: "deviceCode",
          align: "center",
        },
        {
          label: "设备型号",
          prop: "deviceModelName",
          align: "center",
        },
        {
          label: "设备状态",
          prop: "deviceStatus",
          align: "center",
          type: "status",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  watch: {
    filtterData: {
      handler() {
        // this.pageInfo = Object.assign(this.pageInfo, val);
        // console.log("wdwedasdsad-->", this.pageInfo);
        this.getList();
      },
      // immediate: true,
      deep: true, //开启深度监听
    },
  },
  methods: {
    // 点击表格行
    rowClick(row) {
      this.dialogTableVisible = true;
      this.accessControlChannelList = row.accessControlChannelList;
    },
    // 状态显示
    statusFilter(status) {
      let str = "";
      const statusMap = { OFF: "离线", ON: "在线" };
      str = statusMap[status];
      return str;
    },
    // 获取列表数据
    async getList() {
      try {
        let { $test, filtterData, searchQuery } = this;
        let data = {
          pageNum: searchQuery.pageNum,
          pageSize: searchQuery.pageSize,
        };
        // 获取列表时为保证传多余的参数 需要判断值是否为空  空值不传字段，非空再传
        for (const key in filtterData) {
          if (!$test.isEmpty(filtterData[key])) {
            data[key] = filtterData[key];
          }
        }
        let result = await api.concierge.getList(data);

        if (result.code == 200) {
          // 判断code是否等于200
          // if (result.data.code == 403) {
          //   // 如果data.coded 等于403 说明大华平台登录过期需要重新登录
            
          //   // let dhLogin = await api.common.dhLogin();

          //   // if (dhLogin.code == 200) {
          //   //   // 大华平台登录成功 后重新获取设备列表
          //   //   this.getList();
          //   // }
          // } else {
            // 过滤第一条数据不要
            this.deviceList = result.data.data.pageData.filter((x) => {
              return x.deviceCode != 1000023;
            });
          // }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 根据设备状态设置字体颜色
    setColorClass(data) {
      let str = "";
      switch (data) {
        case "OFF":
          str = `font-red`;

          break;
        case "ON":
          str = `font-green`;
          break;
        default:
          str = `font-blue`;
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.inputFile {
  display: none;
}
.fullWidth-label {
  padding: 0 10px;
  width: 170px;
}
.avatar {
  width: 170px;
  height: 250px;
  display: block;
}
.status-radius {
  display: inline-block;
  &::before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  &.font-green::before {
    background: #67c23a;
  }
  &.font-red::before {
    background: #f56c6c;
  }
}
.font-green {
  color: #67c23a;
}
.font-red {
  color: #f56c6c;
}
.font-blue {
  color: #409eff;
}
</style>
